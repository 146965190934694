import { useQueryService } from 'src/ui/hooks/useQuery'
import { container } from 'src/core/Shared/_di'
import { HotelNotFoundError } from 'src/core/Hotel/domain/HotelNotFoundError'
import { location } from 'src/ui/navigation/location'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import { HotelAdultsOnlyHasChildrenError } from 'src/core/Hotel/domain/HotelAdultsOnlyHasChildrenError'
import { useCart } from 'src/ui/contexts/CartContext'
import { useApplicationMode } from 'src/ui/contexts/ApplicationModeContext'

interface Params {
  id?: string
  skipAnalytics?: boolean
}

export const useHotel = (params?: Params) => {
  const { queryUtils } = useApplicationRouter()
  const languageConfig = useLanguageConfig()
  const { cart } = useCart()
  const { analyticsProcessType } = useApplicationMode()

  const hotelId = isDefined(cart)
    ? cart.hotelId
    : params?.id ?? queryUtils.getRawParam('hotel')
  const arrive = queryUtils.getRawParam('arrive')
  const depart = queryUtils.getRawParam('depart')
  const child = queryUtils.getRawParam('child')
  const hasChildren = isDefined(child) && parseInt(child) > 0

  const cmsLanguage = languageConfig.properties.cmsLanguage
  const hasHotelRequiredDependencies =
    isDefined(hotelId) && isDefined(cmsLanguage)

  const { data: hotel } = useQueryService(
    'hotel-availability-options',
    hasHotelRequiredDependencies ? [hotelId, cmsLanguage, hasChildren] : null,
    () =>
      container.resolve('getHotelById')(
        hotelId!,
        cmsLanguage,
        hasChildren,
        analyticsProcessType,
        params?.skipAnalytics ?? false,
      )(),
    {
      errorDefinitions: [
        {
          error: HotelNotFoundError,
          handler: () => location.toHotelList(arrive, depart),
        },
        {
          error: HotelAdultsOnlyHasChildrenError,
          handler: () => queryUtils.removeParams(['child', 'childages']),
        },
      ],
      quietError: true,
    },
  )

  return hotel
}

import type { Cart } from 'src/core/Cart/domain/Cart.model'
import type { AvailabilityCoupon } from 'src/core/Availability/domain/AvailabilityCoupon'

import styles from './Header.module.scss'
import { classNames } from 'src/ui/utils/classnames'

import { Image } from 'src/ui/components/atoms/Image'
import { Media } from 'src/ui/styles/objects/Media'

import { Text } from 'src/ui/components/atoms/Text'

import { useHideHeaderOnScroll } from 'src/ui/hooks/useHideHeaderOnScroll'
import { useTrans } from 'src/ui/hooks/useTrans'
import { MyBarceloLoginButton } from 'src/ui/views/_components/MyBarceloLoginButton'
import { FC, ReactNode, useEffect, useMemo } from 'react'
import { useCallCenter } from 'src/ui/hooks/useCallCenter'
import { User } from 'src/core/User/domain/User'
import { analytics } from 'src/core/Shared/infrastructure/analytics'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { LanguageButton } from 'src/ui/views/_components/LanguageButton'
import { MenuButton } from 'src/ui/views/_components/MenuButton'
import { CurrencyButton } from 'src/ui/views/_components/CurrencyButton'
import { currencyFormat } from 'src/ui/views/_components/Shared/currencyFormat'
import { useCoupons } from 'src/ui/contexts/CouponsContext'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { PromotionalCouponRibbon } from './PromotionalCouponRibbon'
import { useAvailability } from 'src/ui/contexts/AvailabilityContext'
import { useHotel } from 'src/ui/hooks/queries/useHotel'
import { Divider } from 'src/ui/components'
import { useModal } from 'src/ui/hooks/useModal'
import { useCart } from 'src/ui/contexts/CartContext'
import { barceloHome } from 'src/ui/navigation/cmsLinks'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { PhoneIcon } from 'src/ui/components/atoms/Icon/_icons/interaction/PhoneIcon'
import { getAppliedCoupons } from 'src/core/Cart/domain/Cart.model'
import { getAvailabilityCoupons } from 'src/core/Availability/domain/AvailabilityCoupon'
import { ConfirmExitModal } from './ConfirmExitModal'

interface Props {
  fastBooking?: ReactNode
  user: User | undefined
  smallInMobile?: boolean
  hasToShowHotelName?: boolean
  onLogin: () => void
  onLogout: () => Promise<void>
}

interface CouponValues {
  coupon?: string
  groupcode?: string
  invalidCoupon?: string
  invalidGroupcode?: string
}

export const Header: FC<Props> = ({
  fastBooking,
  user,
  smallInMobile = false,
  hasToShowHotelName = false,
  onLogin,
  onLogout,
}) => {
  const { trans } = useTrans(['common'])

  const { navigate, query, reload, queryUtils } = useApplicationRouter()

  const isHidden = useHideHeaderOnScroll()

  const { callCenterCountryCode, callCenterPhone, callCenterFreePhone } =
    useCallCenter()

  const {
    promotionalCoupon,
    howCouponAppliesInAvailability,
    availabilityCoupon,
  } = useCoupons()
  const { isValidating: isLoadingAvailability } = useAvailability()

  const hotel = useHotel()

  const { cart, isCartValidating } = useCart()

  const { showModal: showConfirmExitModal } = useModal(ConfirmExitModal)

  const { isCouponRibbonVisible, setIsCouponRibbonVisible } = useCoupons()
  const withFastBooking = isDefined(fastBooking)

  const handlePhoneClick = () => {
    analytics.actions.header.clickPhone()
  }

  const handleLanguageSelected = async (cbeLanguage: string) => {
    await navigate.toSameWithReplace({ ...query, locale: cbeLanguage })
    reload()
  }

  const handleCurrencySelected = async (currency: string) => {
    const isoCode = currencyFormat.getIsoCode(currency)
    await queryUtils.setCurrencyParam(isoCode)
  }

  useEffect(() => {
    setIsCouponRibbonVisible(
      !isLoadingAvailability &&
        isDefined(promotionalCoupon) &&
        howCouponAppliesInAvailability !== 'none',
    )
  }, [isLoadingAvailability, promotionalCoupon, howCouponAppliesInAvailability])

  const handleLogoClick = () => {
    if (isDefined(cart) || isCartValidating) {
      showConfirmExitModal()
    } else {
      window.location.href = barceloHome()
    }
  }

  const { coupon, groupcode, invalidCoupon, invalidGroupcode } = useMemo(
    () => getQuantumCoupons(cart, availabilityCoupon),
    [cart, availabilityCoupon],
  )

  return (
    <header
      className={classNames(
        styles.container,
        isHidden && styles.hidden,
        isCouponRibbonVisible && styles.withRibbon,
      )}
      data-target-is-user-logged={isDefined(user)}
      data-quantum-coupon={coupon}
      data-quantum-groupcode={groupcode}
      data-quantum-landings1-invalidcoupon={invalidCoupon}
      data-quantum-landings1-invalidgroupcode={invalidGroupcode}
    >
      {isCouponRibbonVisible && isDefined(promotionalCoupon) && (
        <PromotionalCouponRibbon
          isVisible={isCouponRibbonVisible}
          coupon={promotionalCoupon}
          onClose={() => {
            setIsCouponRibbonVisible(false)
          }}
        />
      )}
      <div
        className={classNames(
          styles.header,
          smallInMobile && styles.smallHeader,
          withFastBooking && styles.withFastBooking,
        )}
      >
        <div className={styles.content}>
          <div className={styles.contentWrapper}>
            <button onClick={handleLogoClick} className="cursor-pointer">
              <Media laptop desktop>
                <Image
                  src="/assets/images/main-logo.svg"
                  height={32}
                  width={91}
                  alt="barcelo-logo"
                />
              </Media>
              <Media tablet mobile>
                <Image
                  src="/assets/images/main-logo.svg"
                  height={24}
                  width={91}
                  alt="barcelo-logo"
                />
              </Media>
            </button>
            {hasToShowHotelName && (
              <Media tablet mobile>
                <Divider dir="vertical" className={styles.divider} />
                <Text
                  fontStyle="xs-300"
                  color="light"
                  className={styles.hotelName}
                >
                  {hotel?.name}
                </Text>
              </Media>
            )}
          </div>

          <nav aria-label={trans('nav_main-navigation_aria-label')}>
            <Media mobile tablet>
              <ul className={styles.menu}>
                <li>
                  <a
                    data-data-phone={callCenterCountryCode}
                    onClick={handlePhoneClick}
                    href={`tel: ${callCenterPhone}`}
                    title={trans('header_call')}
                    className="touch-security-zone flex items-center justify-center"
                  >
                    <Icon icon={PhoneIcon} size="l" color="icon-light" />
                  </a>
                </li>
                <li>
                  <MyBarceloLoginButton
                    onLogin={onLogin}
                    user={user}
                    onLogout={onLogout}
                  />
                </li>
                <li>
                  <MenuButton
                    onSelectLanguage={handleLanguageSelected}
                    onSelectCurrency={handleCurrencySelected}
                  />
                </li>
              </ul>
            </Media>

            {/* // TODO - Skeleton sobre fondo oscuro? */}
            <Media laptop desktop>
              <ul className={styles.menu}>
                <li>
                  <Text as="div" fontStyle="xs-300" color="light">
                    {trans('header_call')}
                  </Text>
                  <a
                    data-data-phone={callCenterCountryCode}
                    onClick={handlePhoneClick}
                    href={`tel:${callCenterPhone}`}
                    title={trans('header_call')}
                  >
                    <Text
                      data-data-phone={callCenterCountryCode}
                      as="div"
                      fontStyle="m-300"
                      color="light"
                    >
                      {callCenterPhone}
                      {callCenterFreePhone}
                    </Text>
                  </a>
                </li>
                <li>
                  <LanguageButton onSelectLanguage={handleLanguageSelected} />
                </li>
                <li>
                  <CurrencyButton onSelectCurrency={handleCurrencySelected} />
                </li>
                <li>
                  <MyBarceloLoginButton
                    onLogin={onLogin}
                    user={user}
                    onLogout={onLogout}
                  />
                </li>
              </ul>
            </Media>
          </nav>
        </div>
      </div>
      {fastBooking}
    </header>
  )
}

function getQuantumCoupons(
  cart: Cart | undefined,
  availabilityCoupon: AvailabilityCoupon | undefined,
): CouponValues {
  return (
    getAppliedCoupons(cart) || getAvailabilityCoupons(availabilityCoupon) || {}
  )
}

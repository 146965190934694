import { useState } from 'react'
import { container } from 'src/core/Shared/_di'

export type ApplicationExperience = 'default' | 'without-barcelo-experience'

export const useApplicationExperience = () => {
  const storageRepository = container.resolve(
    'applicationExperienceStorageRepository',
  )
  const [experience, setExperience] = useState<ApplicationExperience>(() => {
    return storageRepository.getApplicationExperience()
  })

  const updateApplicationExperience = (
    newExperience: ApplicationExperience,
  ) => {
    storageRepository.setApplicationExperience(newExperience)
    setExperience(newExperience)
  }

  const clearApplicationExperience = () => {
    updateApplicationExperience('default')
  }

  const setNoBarceloApplicationExperience = () => {
    updateApplicationExperience('without-barcelo-experience')
  }

  return {
    experience,
    clearApplicationExperience,
    setNoBarceloApplicationExperience,
  }
}

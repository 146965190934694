import { Price } from 'src/core/Shared/domain/Price.model'
import { useCurrency } from '../contexts/CurrencyContext'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import { useMarket } from '../contexts/MarketContext'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export interface FormatOptions {
  absolute?: boolean
  showAlwaysGross?: boolean
  showAlwaysNet?: boolean
  roundUp?: boolean
  roundOff?: boolean
}

export const usePriceFormatter = () => {
  const { getCurrencySymbol } = useCurrency()
  const { marketPrice } = useMarket()
  const languageConfig = useLanguageConfig()

  const symbolBefore =
    languageConfig?.currencyProperties.currencySymbolBeforeNumber
  const symbolAfter =
    !languageConfig?.currencyProperties.currencySymbolBeforeNumber
  const isMarketWithNetPrice = marketPrice?.netPrices

  const getSymbol = (currency: string) => getCurrencySymbol(currency)

  const formatPrice = (
    grossPrice: Price,
    options: FormatOptions = {
      absolute: false,
      showAlwaysGross: false,
      showAlwaysNet: false,
      roundUp: false,
      roundOff: false,
    },
    netPrice?: Price,
  ) => {
    const price = isMarketWithNetPrice && netPrice ? netPrice : grossPrice
    const symbol = getSymbol(price.currency)

    const formattedValue = applyOptionalFormatOptions(price.value, options)
    const valueWithThousands = formatThousands(formattedValue, {
      hasToSkipDecimals: options.roundUp || options.roundOff,
    })

    const nbspUnicode = '\u00A0'
    if (symbolBefore) {
      return `${symbol}${nbspUnicode}${valueWithThousands}`
    }

    return `${valueWithThousands}${nbspUnicode}${symbol}`
  }

  const getPriceByMarketAndConfig = (
    grossPrice: Price,
    netPrice?: Price,
    options?: FormatOptions,
  ) => {
    if (options?.showAlwaysGross) {
      return grossPrice
    }

    if (netPrice && (isMarketWithNetPrice || options?.showAlwaysNet)) {
      return netPrice
    }

    return grossPrice
  }

  const formatValue = (
    grossPrice: Price,
    netPrice: Price | undefined,
    options: FormatOptions = {
      absolute: false,
      showAlwaysGross: false,
      showAlwaysNet: false,
      roundUp: false,
      roundOff: false,
    },
  ) => {
    const { value } = getPriceByMarketAndConfig(grossPrice, netPrice, options)
    const formattedValue = applyOptionalFormatOptions(value, options)
    return formatThousands(formattedValue, {
      hasToSkipDecimals: options.roundUp || options.roundOff,
    })
  }

  const formatThousands = (
    value: number,
    { hasToSkipDecimals }: { hasToSkipDecimals: boolean | undefined },
  ) => {
    const splittedValue = Number(value.toFixed(2)).toString().split('.')

    const thousandsSeparator = languageConfig?.currencyProperties
      .commaAsThousandsSeparator
      ? ','
      : '.'
    const decimalSeparator = languageConfig?.currencyProperties
      .commaAsThousandsSeparator
      ? '.'
      : ','

    const integer = splittedValue[0]
    const decimals = splittedValue[1] ?? ''
    const twoDecimals = decimals.padEnd(2, '0')

    const thousandsRegex = /\B(?=(\d{3})+(?!\d))/g

    return `${integer.replace(thousandsRegex, thousandsSeparator)}${
      isDefined(hasToSkipDecimals) && hasToSkipDecimals
        ? ''
        : decimalSeparator + twoDecimals
    }`
  }

  return {
    symbolBefore,
    symbolAfter,
    getSymbol,
    formatPrice,
    formatValue,
  }
}

const applyOptionalFormatOptions = (
  value: number,
  options: FormatOptions,
): number => {
  value = options.absolute ? Math.abs(value) : value
  value = options.roundUp ? Math.ceil(value) : value
  value = options.roundOff ? Math.round(value) : value

  return value
}

import {
  createContext,
  ReactNode,
  FC,
  useContext,
  useEffect,
  useRef,
  MutableRefObject,
} from 'react'
import {
  Availability,
  AvailabilityCriteria,
  AvailabilityTypeApplied,
  howAvailabilityAppliesInReservation,
  shouldGetAvailabilityWithGroupCode,
} from 'src/core/Availability/domain/Availability.model'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useQueryService } from 'src/ui/hooks/useQuery'
import { container } from 'src/core/Shared/_di'
import {
  AvailabilityError,
  AvailabilityErrorType,
} from 'src/core/Availability/domain/AvailabilityError'
import { location } from 'src/ui/navigation/location'
import { useCart } from 'src/ui/contexts/CartContext'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { getAvailabilityCriteriaType } from 'src/core/Availability/domain/AvailabilityCriteria'
import { DefaultParamValues } from 'src/ui/hooks/useApplicationRouter/buildQueryUtils'
import { CustomEvent } from 'src/core/Shared/infrastructure/eventsManager'
import { useAvailabilityCriteria } from 'src/ui/views/AvailableRooms/useAvailabilityCriteria'
import { useLanguageConfig } from './LanguageConfigContext'
import { useApplicationExperience } from '../hooks/useApplicationExperience'
import { useApplicationMode } from './ApplicationModeContext'
import { Reservation } from 'src/core/Shared/domain/Reservation.model'

interface AvailabilityContext {
  availability: Availability | undefined
  availabilityCriteria: AvailabilityCriteria | undefined
  error: any | undefined
  isValidating: boolean
  onAvailabilityRef:
    | MutableRefObject<((availability: Availability) => void) | undefined>
    | undefined
  getAvailabilityFromReservation: (
    availabilityCriteria: AvailabilityCriteria,
    reservation: Reservation,
  ) => Promise<AvailabilityTypeApplied>
}

const _default: AvailabilityContext = {
  availability: undefined,
  availabilityCriteria: undefined,
  error: undefined,
  isValidating: false,
  onAvailabilityRef: undefined,
  getAvailabilityFromReservation: () => Promise.resolve('all'),
}

export const AvailabilityContext = createContext<AvailabilityContext>(_default)

interface Props {
  children: ReactNode
}

export const AvailabilityProvider: FC<Props> = ({ children }) => {
  const { mode } = useApplicationMode()
  const { availabilityCriteria } = useAvailabilityCriteria(mode)
  const { cart } = useCart()
  const { getRawParams, navigate, query } = useApplicationRouter()
  const { clearApplicationExperience, setNoBarceloApplicationExperience } =
    useApplicationExperience()
  const { language } = useLanguageConfig()
  const onAvailabilityRef = useRef<(availiability?: Availability) => void>()

  const getAvailabilityFromReservation = async (
    availabilityCriteria: AvailabilityCriteria,
    reservation: Reservation,
  ) => {
    try {
      const availability =
        await container.resolve('getAllAvailability')(availabilityCriteria)

      if (isUndefined(availability)) {
        return 'api-error' as AvailabilityTypeApplied
      }

      const availabilityType = howAvailabilityAppliesInReservation(
        availability,
        reservation,
      )

      return availabilityType
    } catch (error) {
      if (
        error instanceof AvailabilityError &&
        error.type === AvailabilityErrorType.HOTEL_AVAILABILITY_NOT_FOUND_ERROR
      ) {
        if (reservation.roomStays.length === 1) {
          return 'none-single' as AvailabilityTypeApplied
        }
        return 'none-multi' as AvailabilityTypeApplied
      }

      return 'api-error' as AvailabilityTypeApplied
    }
  }

  const updatedAvailability = async () => {
    const { availability } = await getAvailability()

    onAvailabilityRef.current?.(availability)
    return availability
  }

  async function getAvailability() {
    clearApplicationExperience()
    if (shouldGetAvailabilityWithGroupCode(availabilityCriteria)) {
      const result = await container.resolve('getAvailabilityByGroupCoupon')(
        availabilityCriteria,
        language,
        setNoBarceloApplicationExperience,
      )
      return { availability: result?.availability }
    }

    return {
      availability:
        await container.resolve('getAllAvailability')(availabilityCriteria),
    }
  }

  const {
    data: availability,
    error,
    isValidating,
    mutate: getFreshData,
  } = useQueryService<Availability | undefined>(
    `availability-${getAvailabilityCriteriaType(availabilityCriteria)}`,
    mode === 'funnel' ? [availabilityCriteria, cart] : null,
    updatedAvailability,
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      shouldRetryOnError: false,
      overrideOnError: async error => {
        //TODO: Migrar a un gestor de errores en cuanto lo montemos
        const params = getRawParams(['arrive', 'depart', 'currency'])
        if (isUndefined(error)) {
          return
        }

        if (
          error instanceof AvailabilityError &&
          error.type === AvailabilityErrorType.INVALID_HOTEL_ERROR
        ) {
          location.toHotelList(params?.arrive, params?.depart)
          return
        }

        if (
          error instanceof AvailabilityError &&
          error.type === AvailabilityErrorType.CURRENCY_CONVERSION_ERROR &&
          params?.currency !== DefaultParamValues.currency
        ) {
          await navigate.toSameWithReload({
            ...query,
            currency: DefaultParamValues.currency,
          })
        }
      },
    },
  )

  useEffect(() => {
    return container
      .resolve('eventsManager')
      .on(CustomEvent.GET_AVAILABILITY_FRESH_DATA, getFreshData)
  }, [getFreshData])

  return (
    <AvailabilityContext.Provider
      value={{
        availability,
        availabilityCriteria,
        error,
        isValidating,
        onAvailabilityRef,
        getAvailabilityFromReservation,
      }}
    >
      {children}
    </AvailabilityContext.Provider>
  )
}

export const useAvailability = () => useContext(AvailabilityContext)

import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { useCurrency } from 'src/ui/contexts/CurrencyContext'
import { useMemo } from 'react'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { buildAvailabilityCriteriaFromPrimitives } from 'src/core/Availability/domain/AvailabilityCriteria'
import { ApplicationMode } from 'src/core/Shared/domain/ApplicationMode.model'

export const useAvailabilityCriteria = (mode: ApplicationMode = 'funnel') => {
  const { queryUtils } = useApplicationRouter()
  const { isCurrencyValid } = useCurrency()

  const { data: availabilityCriteria, errors } = useMemo(() => {
    if (mode === 'management') {
      return { data: undefined, errors: undefined }
    }

    const { data: availabilityCriteriaParams, errors } =
      queryUtils.getAvailabilityCriteriaParams(isCurrencyValid)

    const availabilityCriteriaPrimitive = isDefined(availabilityCriteriaParams)
      ? {
          ...availabilityCriteriaParams,
          membership: true,
        }
      : undefined

    if (isUndefined(availabilityCriteriaPrimitive)) {
      return {
        data: undefined,
        errors,
      }
    }

    return {
      data: buildAvailabilityCriteriaFromPrimitives(
        availabilityCriteriaPrimitive,
      ),
      errors,
    }
  }, [isCurrencyValid, mode, queryUtils])

  return {
    errors,
    availabilityCriteria,
  }
}
